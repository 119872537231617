<template>
  <div v-if="!isLoading" class="box box-default">
    <div class="box-header">
      <div class="flex justify-between align-items-center gap-8">
        <h3 class="box-title nowrap">Users</h3>
        <div class="row" style="flex-grow: 1">
          <div class="col-md-6 text-right">
            <span v-if="searching" class="text-danger text-bold" style="display: inline-block; padding-top: 5px;">
              {{ searching }}
            </span>
          </div>
          <div class="col-md-3">
            <div class="flex gap-8 align-items-center justify-between">
              <label class="mb-0 no-wrap">
                <input v-model="isVerified" name="isVerified" type="radio" value=""
                  @change="searchUsersByVerifiedStatus()" />
                All</label>
              <label class="mb-0 no-wrap">
                <input v-model="isVerified" name="isVerified" type="radio" value="1"
                  @change="searchUsersByVerifiedStatus()" />
                Verified</label>
              <label class="mb-0 no-wrap">
                <input v-model="isVerified" name="isVerified" type="radio" value="0"
                  @change="searchUsersByVerifiedStatus()" />
                Unverified</label>
              <label class="mb-0 no-wrap">
                <input v-model="disabled" name="isVerified" type="radio" value="1"
                  @change="searchUsersByDisabledStatus()" />
                Disabled</label>
            </div>
          </div>
          <div class="col-md-3">
            <input v-model="keyword" aria-label="Search" autofocus="autofocus" class="form-control" ref="searchInput"
              placeholder="Search..." type="text" @keyup="searchUsers($event)" />
          </div>
        </div>
      </div>
    </div>
    <div class="box-body no-padding">
      <div v-if="meta.total" class="footer">
        <pagination :meta="meta" @pageChange="updatePage"></pagination>
      </div>
      <div class="table-responsive">
        <table class="table table-condensed table-striped table-borderless">
          <thead>
            <tr>
              <th class="pointer" @click="sort('ID')">
                ID
                <span v-if="sortKey === 'ID'" class="ml-4">
                  <i class="fa fa-sort"></i>
                </span>
              </th>
              <th class="pointer" @click="sort('OwnerNickName')">
                Owner Nick Name
                <span v-if="sortKey === 'OwnerNickName'" class="ml-4">
                  <i class="fa fa-sort"></i>
                </span>
              </th>
              <th class="pointer" @click="sort('ShopTitle')">
                Shop Title
                <span v-if="sortKey === 'ShopTitle'" class="ml-4">
                  <i class="fa fa-sort"></i>
                </span>
              </th>
              <th class="pointer" @click="sort('Email')">
                Email
                <span v-if="sortKey === 'Email'" class="ml-4">
                  <i class="fa fa-sort"></i>
                </span>
              </th>
              <th class="pointer" @click="sort('BusinessAccount')">
                Account Type
                <span v-if="sortKey === 'BusinessAccount'" class="ml-4">
                  <i class="fa fa-sort"></i>
                </span>
              </th>
              <th class="pointer" @click="sort('FinalVerification')">
                Status
                <span v-if="sortKey === 'FinalVerification'" class="ml-4">
                  <i class="fa fa-sort"></i>
                </span>
              </th>
              <th class="pointer" @click="sort('RegisteredOn')">
                Registered On
                <span v-if="sortKey === 'RegisteredOn'" class="ml-4">
                  <i class="fa fa-sort"></i>
                </span>
              </th>
              <th class="pointer" @click="sort('LastLoginOn')">
                Last Login On
                <span v-if="sortKey === 'LastLoginOn'" class="ml-4">
                  <i class="fa fa-sort"></i>
                </span>
              </th>
              <th>Roles</th>
              <th class="no-wrap">Total Transactions</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-if="meta && meta.total">
              <tr v-for="(user, index) in users" :key="index">
                <td class="pl-16">
                  <router-link :to="{ name: 'user-detail', params: { id: user.ID } }" tag="a">
                    {{ user.ID }}
                  </router-link>
                </td>
                <td>{{ user.Name }}</td>
                <td>{{ user.ShopTitle }}</td>
                <td>{{ user.Email }}</td>
                <td>{{ user.BusinessAccount ? 'Business' : 'Personal' }}</td>
                <td v-html="formatStatus(user.FinalVerification, user.Disabled)"></td>
                <td>{{ formatDate(user.RegisteredOn) }}</td>
                <td>{{ formatDate(user.LastLoginOn) }}</td>
                <td v-html="formatRoles(user.Roles)"></td>
                <td>{{ user.TotalTransactions }}</td>
                <td>
                  <div class="flex gap-4 align-items-center">
                    <button v-if="isAuthorized('assign-roles')" class="btn btn-primary btn-flat btn-sm"
                      @click="launchManageRoleModal(user)">Manage Role
                    </button>
                    <button v-else class="btn btn-default btn-flat btn-sm">
                      <i class="fa fa-lock"></i>
                    </button>
                    <button class="btn btn-primary btn-flat btn-sm" @click="cloneUser(user)">
                      <i v-if="loader === user.ID" class="fa fa-spinner fa-spin mr-8"></i>
                      <span>Clone</span>
                    </button>
                    <router-link tag="a" :to="{ name: 'kyc', params: { id: user.ID } }"
                      class="btn btn-primary btn-flat btn-sm">KYC</router-link>
                    <router-link tag="a" :to="{ name: 'user-detail', params: { id: user.ID }, query: { edit: true } }"
                      class="btn btn-primary btn-flat btn-sm">Edit</router-link>
                    <router-link tag="a" :to="{ name: 'user-detail', params: { id: user.ID } }"
                      class="btn btn-primary btn-flat btn-sm">User</router-link>
                    <button class="btn btn-danger btn-flat btn-sm" v-if="user.Disabled"
                      @click="deleteUser(user)">Delete</button>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="11">No record found!</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div v-if="meta.total" class="footer">
        <pagination :meta="meta" @pageChange="updatePage"></pagination>
      </div>
    </div>
    <assign-role-modal></assign-role-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AssignRoleModal from "@/components/modals/AssignRoleModal.vue";
import Pagination from "@/components/Pagination.vue";
import { START_LOADING, STOP_LOADING } from "@/store/keys";
import { isAuthorized, saveNotification, formatDate } from "@/lib/utils";

export default {
  name: "Users",
  components: {
    AssignRoleModal,
    Pagination,
  },
  data() {
    return {
      keyword: "",
      users: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      timer: null,
      isVerified: "",
      disabled: "",
      searching: "",
      sortKey: "ID",
      order: "desc",
      loader: "",
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "roles",
      "currentUser",
    ]),
  },
  methods: {
    isAuthorized(p) {
      return isAuthorized(p);
    },
    updatePage(page) {
      this.getUsers(page);
    },
    getUsers(page = 1, keyword = "", role = "", isLoader = true) {
      if (isLoader) {
        this.$store.commit(START_LOADING);
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListUsers`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword: keyword ? keyword.trim() : "",
          role,
          isVerified: this.isVerified,
          disabled: this.disabled,
          limit: this.meta.limit,
          order: this.sortKey,
          orderBy: this.order,
        },
      }).then(response => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        if (response.status === 200) {
          this.users = response.data.data.users;
          this.meta = response.data.data.meta;
          if (this.keyword) {
            this.$nextTick(() => this.$refs.searchInput.focus());
          }
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    cloneUser(user) {
      this.loader = user.ID;
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/v2REAPI?Call=CloneMerchantID`, {
        params: {
          ID: user.ID,
          MerchantID: this.currentUser.ID,
          APIKey: this.currentUser.APIKey,
          output: "json",
        },
      }).then(response => {
        this.loader = "";
        if (response.status === 200) {
          this.getUsers(1, this.keyword);
        }
      }).catch(error => {
        this.loader = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    deleteUser(user) {
      this.$swal.fire({
        title: "Delete User",
        text: `Are you sure want to delete user '${user.Name}'`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#dd4b39",
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$http.get(`${apiBaseUrl}/v2REAPI?Call=DeleteCustomer`, {
            params: {
              DeleteUser: user.ID,
              MerchantID: this.currentUser.ID,
              APIKey: this.currentUser.APIKey,
              output: "json",
            },
          }).then(response => {
            if (response.status === 200) {
              this.getUsers(1, this.keyword);
            }
          }).catch(error => {
            this.$toast.fire("", error.data.message, "error");
            saveNotification(error.data.message);
          });
        }
      });
    },
    searchUsersByVerifiedStatus() {
      this.disabled = "";
      this.getUsers(1, this.keyword, "", false);
    },
    searchUsersByDisabledStatus() {
      this.isVerified = "";
      this.getUsers(1, this.keyword, "", false);
    },
    searchUsers(e) {
      if (e.key !== "/" && e.key !== "Control") {
        this.searching = "Searching...";
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.getUsers(1, this.keyword, "", false);
        }, 500);
      }
    },
    formatStatus(status, disabled) {
      const formattedStatus = disabled === 1 ? "Disabled" : (status ? "Verified" : "Unverified");
      const lblClass = disabled === 1 ? "label-danger" : (status ? "label-success" : "label-warning");
      return "<label class=\"label " + lblClass + "\">" + formattedStatus + "</label>";
    },
    formatRoles(roles) {
      let formattedRoles = "";
      if (roles) {
        roles = roles.split(",");
        roles.forEach(id => {
          const role = this._.find(this.roles, { ID: Number.parseInt(id, 10) });
          if (role) {
            formattedRoles += `<label class="label label-info mr-8">${role.Name}</label>`;
          }
        });
      }
      return formattedRoles;
    },
    launchManageRoleModal(user) {
      this.$bus.$emit("assign-role-modal", {
        title: "Manage User Role",
        data: user,
        confirm: () => {
          this.getUsers(this.meta.page, this.keyword);
        },
      });
    },
    handleSlashKey(e) {
      if (e.key === "/") {
        e.preventDefault();
        this.$refs.searchInput.focus();
      }
    },
    sort(key) {
      if (key !== this.sortKey) {
        this.order = "desc";
      } else {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sortKey = key;
      this.getUsers(1, this.keyword);
    },
    formatDate(val) {
      return formatDate(val);
    },
  },
  mounted() {
    if (this.$route.query.role) {
      const roleId = this.$route.query.role;
      this.getUsers(1, "", roleId, true);
    } else {
      this.getUsers();
    }
    window.addEventListener("keydown", this.handleSlashKey);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleSlashKey);
  },
};
</script>

<style scoped></style>
